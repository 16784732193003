import { Observable } from 'rxjs';

import { db } from '@/main';
import { dbNewsDataToNewsItems, NewsItem } from '@/views/Press/news.model';

export const getNewsItems = (): Observable<NewsItem[]> =>
    new Observable(observer => {
        db.ref('/news')
            .once('value')
            .then(snapshot => {
                const data = snapshot.val();
                data ? observer.next(dbNewsDataToNewsItems(data)) : observer.error('no data');
                observer.complete();
            });
    });
