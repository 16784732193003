
import { defineComponent } from 'vue';
import { ElCol, ElDivider, ElMain, ElRow } from 'element-plus';

import { useObservable } from '@/helpers/rxjs.helper';
import { getPhotoSets } from '@/services/photos.service';

export default defineComponent({
    name: 'Photos',
    components: { ElCol, ElDivider, ElMain, ElRow },
    setup() {
        const photoSets = useObservable(getPhotoSets());

        return {
            photoSets
        };
    }
});
