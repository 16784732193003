import { HomeArticle } from '@/views/Home/home-article.model';

export const HomeArticleData = [
    new HomeArticle(
        'Organization Interested in Producing Marauder Documentary',
        '<div><p>The organization, WWII Foundation, produces documentaries on military units, and they\'re interested in producing a new Merrill\'s Marauders documentary! Please visit their website to make donations:</p> <a href="http://www.wwiifoundation.org" target="_blank">http://www.wwiifoundation.org</a>.</div>'
    ),
    new HomeArticle(
        'GoFundMe for Marauders documentary',
        `<div>
            <p>The organization, WWII Foundation, produces documentaries on military units, and they're interested in producing a new Merrill's Marauders documentary! Please visit their Go Fund Me page to make a donation. Anyone donating $100 or more will be listed in the credits in the film.</p>
            <a href="https://www.gofundme.com/f/special-wwii-documentary-needs-your-support?utm_medium=email&utm_source=product&utm_campaign=p_email%2B2300-co-team-welcome&fbclid=IwAR2oKIXWeMUyIgSGhOKNlZxW27Y6TXu0w7nJktk9u49gWIaFcCfJpIBt3sU" target="_blank">
            <img style="width: 100%" src="https://d2g8igdw686xgo.cloudfront.net/59365795_1629840744273835_r.jpeg" />
            </a>
            <a href="https://www.gofundme.com/f/special-wwii-documentary-needs-your-support?utm_medium=email&utm_source=product&utm_campaign=p_email%2B2300-co-team-welcome&fbclid=IwAR2oKIXWeMUyIgSGhOKNlZxW27Y6TXu0w7nJktk9u49gWIaFcCfJpIBt3sU" target="_blank">Click here to donate or read more</a>
        </div>`
    ),
    new HomeArticle(
        "Merrill's Marauder Congressional Gold Medal Ceremony",
        `<div>
            <p>Please click the link below for the most current information about the long-awaited Merrill's Marauder Congressional Gold Medal ceremony -- which will be virtual.  And please carefully read the information. At this time this is the most current information available. </p>
            <a href="http://www.icontact-archive.com/archive?c=1802913&f=35169&s=39762&m=195149&t=eb08ebfe7072c4796cede8414e00cffae63ad1b026f178e5a36ce51fabb94771" target="_blank">Merrill's Marauder Congressional Gold Medal Ceremony</a>
        </div>`
    ),
    new HomeArticle(
        'In loving memory of Michael F. Christner',
        `<div>
            <div>
                <p>In loving memory of Michael F. Christner who passed away unexpectedly Oct 30, 2023 just a few weeks shy of his 68th birthday. His friends and family are coming together on June 8, 2024 to honor and celebrate his life in a way he would appreciate. A low-key, non-traditional and nature-inspired service is fitting for Michael which is why we have selected Buszka’s Funeral Home in Cheektowaga, NY located at 2085 Union Rd W Seneca, NY 14224. The memorial gathering will take place from 11am-noon followed by a service from noon-1 pm. Immediately following, there will be a luncheon nearby (to be announced at the service).</p>
                <p>We hope that many of Michael’s friends both past & present will attend. Please RSVP to Deby Driscoll at <a href="mailto:debyd56@comcast.net">debyd56@comcast.net</a> as soon as possible if you plan on attending the luncheon. In lieu of flowers, donations can be made to Nature Conservancy, Arbor Day Foundation or Habitat for Humanity. Sincere thanks and we hope to see you on June 8th.</p>
            </div>
            <div>
                <b>Date:</b><br />
                <span>June 8th, 11:00am - 1:00pm</span><br /><br />
                <b>Address:</b><br />
                <span>Buszka's Funeral Home</span><br />
                <span>2085 Union Road West</span><br />
                <span>Seneca, NY 14224</span><br /><br />
                <b>RSVP to:</b><br />
                <span>Deby Driscoll at <a href="mailto:debyd56@comcast.net">debyd56@comcast.net</a></span><br /><br />
            </div>
        </div>`
    )
];
