
import { defineComponent } from 'vue';

import HomeCarousel from './HomeCarousel.vue';
import HomeArticles from './HomeArticles.vue';

export default defineComponent({
    name: 'Home',
    components: { HomeCarousel, HomeArticles }
});
