export class ArticleModel {
    public title = '';
    public content = '';
    public route = '';

    constructor(title: string, content: string, route: string) {
        this.title = title;
        this.content = content;
        this.route = route;
    }
}

export type ArticleDataMap = Map<string, ArticleModel>;

export class ArticleDataModel {
    public about: ArticleDataMap;
    public events: ArticleDataMap;
    public photos: ArticleDataMap;
    public links: ArticleDataMap;

    constructor(
        about: ArticleDataMap,
        events: ArticleDataMap,
        photos: ArticleDataMap,
        links: ArticleDataMap
    ) {
        this.about = about;
        this.events = events;
        this.photos = photos;
        this.links = links;
    }
}
