<template>
    <el-main v-if="article" class="article-content">
        <el-row>
            <el-col
                :xs="24"
                :sm="{ span: 22, offset: 1 }"
                :md="{ span: 18, offset: 3 }"
                :lg="{ span: 14, offset: 5 }"
                :xl="{ span: 10, offset: 7 }"
            >
                <h2>{{ article.title }}</h2>
                <el-divider></el-divider>
            </el-col>
        </el-row>

        <el-row>
            <el-col
                :xs="24"
                :sm="{ span: 22, offset: 1 }"
                :md="{ span: 18, offset: 3 }"
                :lg="{ span: 14, offset: 5 }"
                :xl="{ span: 10, offset: 7 }"
            >
                <div v-html="article.content"></div>
            </el-col>
        </el-row>
    </el-main>
</template>

<script lang="ts">
import { ElCol, ElDivider, ElMain, ElRow } from 'element-plus';
import { defineComponent } from 'vue';

import { ArticleModel } from './article.model';

export default defineComponent({
    name: 'Article',
    components: { ElCol, ElDivider, ElMain, ElRow },
    props: {
        article: ArticleModel
    }
});
</script>

<style lang="scss" scoped>
.article-content {
    margin-bottom: 40px;
}
</style>
