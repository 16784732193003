<template>
    <div class="home-carousel">
        <el-carousel :interval="8000" trigger="click" @change="onSlideChange">
            <el-carousel-item v-for="slide in slides" :key="slide">
                <div class="carousel-slide" :style="`background-image: url(${slide.image})`">
                    <div class="carousel-overlay"></div>

                    <div
                        v-if="!isSmallScreen && (slide.title || slide.text)"
                        class="carousel-text-container p-4"
                        :class="{ 'carousel-text-right': slide.textRight }"
                    >
                        <div class="carousel-title font-lg font-bold mb-2">
                            {{ slide.title }}
                        </div>
                        <div class="carousel-text" v-html="slide.text"></div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>

        <div
            v-if="isSmallScreen && (slides[activeSlide].title || slides[activeSlide].text)"
            class="carousel-text-container-small p-4"
            :class="{ 'carousel-text-right': slides[activeSlide].textRight }"
        >
            <div class="carousel-title font-lg font-bold mb-2">
                {{ slides[activeSlide].title }}
            </div>
            <div class="carousel-text" v-html="slides[activeSlide].text"></div>
        </div>
    </div>
</template>

<script lang="ts">
import { ElCarousel, ElCarouselItem } from 'element-plus';
import { map } from 'rxjs/operators';
import { defineComponent, ref, Ref } from 'vue';

import { checkIfSmallScreenOnResize, ScreenSizes } from '@/helpers/resize.helper';
import { useObservable } from '@/helpers/rxjs.helper';
import { getHomeCarouselSlides } from '@/services/carousel.service';
import { HomeCarouselSlide } from './home-carousel.model';

export default defineComponent({
    name: 'HomeCarousel',
    components: { ElCarousel, ElCarouselItem },
    setup() {
        const slides: Ref<HomeCarouselSlide[]> = useObservable(getHomeCarouselSlides(), []);
        const activeSlide = ref(0);

        const isSmallScreen = useObservable(
            checkIfSmallScreenOnResize().pipe(
                map(screenSize => screenSize === ScreenSizes.xs || screenSize === ScreenSizes.sm)
            )
        );

        const onSlideChange = (index: number) => (activeSlide.value = index);

        return {
            slides,
            activeSlide,
            isSmallScreen,
            onSlideChange
        };
    }
});
</script>

<style lang="scss">
.home-carousel {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2);

    .el-carousel,
    .el-carousel__container {
        height: 550px;
    }

    .carousel-slide {
        background-position-x: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        height: 550px;
        position: relative;
        text-align: left;
        width: 100%;
    }

    .carousel-overlay {
        background-color: rgba(0, 0, 0, 0.25);
        height: 550px;
        width: 100%;
    }

    .carousel-text-container {
        background-color: #222;
        border-radius: 3px;
        bottom: 50px;
        color: #fff;
        left: 60px;
        position: absolute;
        width: 400px;
        z-index: 2;
    }

    .carousel-text-right {
        left: auto;
        right: 60px;
    }

    .carousel-text-container-small {
        background-color: #222;
        color: #fff;
    }
}

.el-carousel__arrow {
    background-color: rgba(31, 45, 61, 0.4) !important;
    font-size: 20px !important;
    height: 40px !important;
    outline: none;
    width: 40px !important;
    z-index: 3;

    &:hover {
        background-color: rgba(31, 45, 61, 0.8) !important;
    }
}

.el-carousel__button {
    height: 5px;
}

@media screen and (max-width: 992px) {
    .home-carousel {
        .el-carousel,
        .el-carousel__container,
        .carousel-slide,
        .carousel-overlay {
            height: 375px;
        }
    }
}

@media screen and (max-width: 768px) {
    .home-carousel {
        .el-carousel,
        .el-carousel__container,
        .carousel-slide,
        .carousel-overlay {
            height: 200px;
        }

        .el-carousel__indicators--horizontal {
            left: 40%;
            transform: translateX(-40%);
        }
    }
}
</style>
