import { Observable, of } from 'rxjs';

import { ArticleData } from '@/data/article.data';
import { ArticleDataModel, ArticleModel } from '@/components/Article/article.model';
import { HomeArticleData } from '@/data/home-articles.data';
import { HomeArticle } from '@/views/Home/home-article.model';

export const getArticles = () => of(ArticleData);

export const getArticleByTitle = (
    section: keyof ArticleDataModel,
    title: string
): Observable<ArticleModel | undefined> => of(ArticleData[section].get(title));

export const getHomeArticles = (): Observable<HomeArticle[]> => of(HomeArticleData);
