<template>
    <el-main class="then-and-now">
        <el-row>
            <el-col
                :xs="24"
                :sm="{ span: 22, offset: 1 }"
                :md="{ span: 18, offset: 3 }"
                :lg="{ span: 14, offset: 5 }"
                :xl="{ span: 10, offset: 7 }"
            >
                <h2>Then and Now</h2>
                <el-divider></el-divider>
            </el-col>
        </el-row>

        <el-row>
            <el-col
                :xs="24"
                :sm="{ span: 22, offset: 1 }"
                :md="{ span: 18, offset: 3 }"
                :lg="{ span: 14, offset: 5 }"
                :xl="{ span: 10, offset: 7 }"
                class="photo-sets"
            >
                <div v-for="(set, i) in photoSets" :key="i" class="photo-set">
                    <div class="photo-set-photos">
                        <img
                            v-for="(image, j) in set.images"
                            :key="j"
                            :src="image"
                            :class="{ 'single-photo': set.images.length === 1 }"
                        />
                    </div>
                    <div v-html="set.html" class="photo-set-caption font-sm mb-3"></div>
                </div>
            </el-col>
        </el-row>
    </el-main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ElCol, ElDivider, ElMain, ElRow } from 'element-plus';

import { useObservable } from '@/helpers/rxjs.helper';
import { getPhotoSets } from '@/services/photos.service';

export default defineComponent({
    name: 'Photos',
    components: { ElCol, ElDivider, ElMain, ElRow },
    setup() {
        const photoSets = useObservable(getPhotoSets());

        return {
            photoSets
        };
    }
});
</script>

<style lang="scss" scoped>
.photo-sets {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.photo-set {
    width: 300px;
}

.photo-set-photos {
    display: flex;
    justify-content: center;
    max-width: 400px;

    img {
        height: auto;
        max-height: 200px;
        width: auto;
    }

    .single-photo {
        max-width: 400px;
    }
}

.photo-set-caption {
    text-align: center;
}
</style>
