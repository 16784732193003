<template>
    <el-menu-item index="home" @click="openRouterLink('/')">Home</el-menu-item>

    <el-submenu v-if="articles" index="about">
        <template #title>About</template>
        <el-menu-item
            v-for="article of articles.about.values()"
            :key="article.index"
            index="about"
            @click="openArticlePage(article)"
        >
            {{ article.title }}
        </el-menu-item>
    </el-submenu>

    <el-submenu index="events">
        <template #title>Events</template>
        <el-menu-item
            v-for="article of articles.events.values()"
            :key="article.index"
            index="events"
            @click="openArticlePage(article)"
        >
            {{ article.title }}
        </el-menu-item>
    </el-submenu>

    <el-submenu index="photos">
        <template #title>Photos</template>
        <el-menu-item
            v-for="article of articles.photos.values()"
            :key="article.index"
            index="photos"
            @click="openArticlePage(article)"
        >
            {{ article.title }}
        </el-menu-item>
    </el-submenu>

    <el-menu-item index="press" @click="openRouterLink('/press')">Press</el-menu-item>

    <el-submenu index="links/resources">
        <template #title>Links/Resources</template>
        <el-menu-item
            v-for="article of articles.links.values()"
            :key="article.index"
            index="links"
            @click="openArticlePage(article)"
        >
            {{ article.title }}
        </el-menu-item>
    </el-submenu>
</template>

<script lang="ts">
import { ElMenuItem, ElSubmenu } from 'element-plus';
import { defineComponent } from 'vue';

import { ArticleModel } from '@/components/Article/article.model';
import { useObservable } from '@/helpers/rxjs.helper';
import router from '@/router';
import { getArticles } from '@/services/article.service';

export default defineComponent({
    name: 'HeaderMenu',
    components: { ElMenuItem, ElSubmenu },
    setup() {
        const articles = useObservable(getArticles());

        const openRouterLink = (route: string) => router.push(route);

        const openArticlePage = (article: ArticleModel) => openRouterLink(article.route);

        return {
            articles,
            openRouterLink,
            openArticlePage
        };
    }
});
</script>

<style lang="scss" scoped>
.header-submenu {
    border: none;
    border-radius: 0;
    transform: translate(481.6px, 70.4px);
}
</style>
