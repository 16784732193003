<template>
    <el-container class="navbar">
        <el-header>
            <el-row type="flex" align="middle">
                <el-col :span="isSmallScreen ? 24 : 8" align="middle" justify="center">
                    <div class="navbar-brand">
                        <router-link to="/">
                            <img src="@/assets/logo.png" height="48" />
                        </router-link>
                    </div>
                </el-col>

                <el-col v-if="!isSmallScreen" :span="16">
                    <el-menu
                        class="float-right"
                        :default-active="currentActiveMenu"
                        mode="horizontal"
                    >
                        <HeaderMenu></HeaderMenu>
                    </el-menu>
                </el-col>
            </el-row>
            <el-row v-if="isSmallScreen" class="header-menu-small" type="flex" align="middle">
                <el-col :span="24">
                    <el-menu mode="vertical" menu-trigger="click" :unique-opened="true">
                        <el-submenu class="header-menu-small-main" index="0">
                            <template #title>
                                <div>Menu</div>
                            </template>
                            <HeaderMenu></HeaderMenu>
                        </el-submenu>
                    </el-menu>
                </el-col>
            </el-row>
        </el-header>
    </el-container>
</template>

<script lang="ts">
import { ElCol, ElContainer, ElHeader, ElMenu, ElRow, ElSubmenu } from 'element-plus';
import { map } from 'rxjs/operators';
import { defineComponent, ref, watch } from 'vue';

import { checkIfSmallScreenOnResize, ScreenSizes } from '@/helpers/resize.helper';
import { useObservable } from '@/helpers/rxjs.helper';
import HeaderMenu from './HeaderMenu.vue';
import router from '@/router';

export default defineComponent({
    name: 'Header',
    components: { ElCol, ElContainer, ElHeader, ElMenu, ElRow, ElSubmenu, HeaderMenu },
    setup() {
        const isSmallScreen = useObservable(
            checkIfSmallScreenOnResize().pipe(
                map(screenSize => screenSize === ScreenSizes.xs || screenSize === ScreenSizes.sm)
            )
        );

        const currentActiveMenu = ref(router.currentRoute.value.name);

        watch(
            () => router.currentRoute.value.name,
            () => (currentActiveMenu.value = router.currentRoute.value.name)
        );

        return {
            isSmallScreen,
            ScreenSizes,
            currentActiveMenu
        };
    }
});
</script>

<style lang="scss">
@import '@/styles/colors.scss';

.navbar {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12);
    position: relative;
    z-index: 15;

    .el-header {
        height: 61px !important;
    }

    .navbar-brand {
        img {
            max-height: 3em;
        }
    }

    .header-menu-small {
        .el-menu {
            background-color: $primary !important;
        }

        .header-menu-small-main {
            margin-right: -1px;
        }

        .header-menu-small-main > .el-submenu__title:first-child {
            &:hover,
            &:focus,
            & {
                background-color: $primary !important;
            }
        }

        .el-submenu .el-menu-item {
            color: $white;
            height: 40px;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;

            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-tap-highlight-color: transparent;
        }

        .el-submenu__title {
            align-items: center;
            color: $white;
            display: flex;
            height: 40px !important;
            justify-content: space-between;
            line-height: 40px !important;

            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-tap-highlight-color: transparent;
        }

        .el-menu-item,
        .el-submenu__title {
            &:hover,
            &:focus {
                background-color: $secondary;
            }
        }

        .el-icon-arrow-down {
            color: $white;
        }
    }

    @media screen and (max-width: 992px) {
        .el-header {
            height: 97px !important;
            padding: 0;
        }

        .navbar-brand img {
            max-height: 3em;
            padding-top: 5px;
        }
    }

    @media screen and (max-width: 768px) {
        .navbar-brand img {
            max-height: 2.5em;
            padding: 8px 0;
        }
    }
}
</style>
