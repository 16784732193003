
import { defineComponent } from 'vue';

import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';

export default defineComponent({
    name: 'App',
    components: { Footer, Header }
});
