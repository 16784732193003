<template>
    <el-container class="home-articles">
        <div class="home-articles-grid">
            <el-card class="articles-item-card" v-for="(item, index) in items" :key="index">
                <template #header>
                    <span class="font-lg">{{ item.title }}</span>
                </template>
                <div class="articles-item-html" v-html="item.content"></div>
            </el-card>
        </div>
    </el-container>
</template>
<script lang="ts">
import { ElCard, ElContainer } from 'element-plus';
import { map } from 'rxjs/operators';
import { defineComponent, Ref } from 'vue';

import { useObservable } from '@/helpers/rxjs.helper';
import { getHomeArticles } from '@/services/article.service';
import { HomeArticle } from './home-article.model';

export default defineComponent({
    name: 'HomeArticles',
    components: { ElCard, ElContainer },
    setup() {
        const items: Ref<HomeArticle[]> = useObservable(
            getHomeArticles().pipe(map(articles => articles.reverse())),
            []
        );

        return {
            items
        };
    }
});
</script>

<style lang="scss">
@import '@/styles/colors.scss';

.home-articles {
    margin: 0 20px;

    .home-articles-grid {
        column-count: 3;
        column-width: calc(100vh / 3);
    }

    .articles-item-card {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        break-inside: avoid;
        margin-bottom: 1rem;

        .el-card {
            box-shadow: none;
        }

        .el-card__header {
            background-color: $accent;
            color: $white;
            font-weight: bold;
        }

        .el-card__body {
            padding-top: 0;
        }
    }
}
</style>
