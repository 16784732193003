interface DbNewsItem {
    id: string;
    link: string;
    title: string;
    published: string;
    source: {
        href: string;
        title: string;
    };
}

interface NewsItemSource {
    href: string;
    title: string;
}

export class NewsItem {
    public id = '';
    public link = '';
    public title = '';
    public published: Date | undefined;
    public source: NewsItemSource | undefined;

    constructor(
        id: string,
        link: string,
        title: string,
        published?: Date,
        source?: NewsItemSource
    ) {
        this.id = id;
        this.link = link;
        this.title = title;
        this.published = published;
        this.source = source;
    }
}

export const dbNewsDataToNewsItems = (data: DbNewsItem[]): NewsItem[] =>
    data.map(
        item =>
            new NewsItem(
                item.id,
                item.link,
                item.title.split(' - ')[0],
                item.published ? new Date(item.published) : undefined,
                item.source
            )
    );
