import { db } from "@/main";
import { PhotoSet } from "@/models/photos.model";
import { Observable } from "rxjs";

export const getPhotoSets = (): Observable<PhotoSet[]> =>
    new Observable(observer => {
        db.ref('/photos/sets')
            .once('value')
            .then(snapshot => {
                const data = snapshot.val();
                data ? observer.next(data) : observer.error('no data');
                observer.complete();
            });
    });